import { combineReducers } from 'redux';

// Front
import Layout from './layout/reducer';

// Authentication
// import Auth from "./auth/reducer"

// Campaigns
import Campaigns from './campaigns/reducer';

//contacts
import contacts from './contacts/reducer';

//Dashboard
import Dashboard from './dashboard/reducer';

const rootReducer = combineReducers({
  // public
  Layout,
  // Auth,
  Campaigns,
  contacts,
  Dashboard,
});

export default rootReducer;
