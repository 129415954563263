import axios from 'axios';
import { BASE_URL } from '../constants/api';
import { getValueFromLocalStorage, removeSession } from 'helpers';

const Axios = axios.create({
  baseURL: BASE_URL,
});

export const setHeaders = token => {
  Axios.defaults.headers.common['x-access-token'] = token;
};

Axios.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
);

Axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.data?.message?.includes('Failed to authenticate token.'))
      return removeSession();
    else return Promise.reject(error);
  }
);

export async function get(url, params = null) {
  try {
    const { status, data } = await Axios.get(url, { params });
    return { status, data };
  } catch (error) {
    return error.response;
  }
}

export async function post({ url, data, config = {} }) {
  const dataObj = data instanceof FormData ? data : { ...data };
  return await Axios.post(url, dataObj, { ...config }).then(
    response => response
  );
}

export async function put(url, data, config = {}) {
  const dataObj = data instanceof FormData ? data : { ...data };
  return await Axios.put(url, dataObj, { ...config }).then(response => response);
}

export async function del(url, config = {}) {
  return await Axios.delete(url, { ...config }).then(response => response);
}
