import React from 'react';
import Compressor from 'compressorjs';
import { validateFileSize, checkFileType } from '../helpers/';

export const useCompressFile = (size = 1) => {
  const [compressing, setCompressing] = React.useState(false);
  const [fileReady, setFileReady] = React.useState(null);
  const [fileUrl, setFileUrl] = React.useState('');
  const [inputName, setInputName] = React.useState('');

  const onChangeFile = ({ target, name }) => {
    const file = target.files[0];
    setInputName(name);
    if (checkFileType(file.name).match('^(pdf|doc|docx)$'))
      validateSize(file, name, target, null);
    else {
      setCompressing(true);
      new Compressor(file, {
        width: 720,
        quality: 0.7, // 0.6 can also be used, but its not recommended to go below.
        success: result => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          const compressed = new File([result], file.name);
          validateSize(compressed, name, target);
        },
      });
    }
  };

  const validateSize = (file, target, type = 'img') => {
    if (type === 'img')
      setTimeout(() => {
        setCompressing(false);
      }, 800);
    const message =
      type === 'img'
        ? 'We tried to compress the file but the result exceeds 1MB, please upload a file of maximum 1MB.'
        : 'The file size cannot exceed 1MB.';

    if (validateFileSize(file, size)) {
      const url = URL.createObjectURL(file);
      setFileReady(file);
      setFileUrl(url);
    } else {
      window.alert(message);
      target.files = null;
      target.value = '';
    }
  };

  return {
    onChangeFile,
    compressing,
    file: fileReady,
    fileUrl,
    inputName,
  };
};
