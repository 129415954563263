import {
  STORAGE_NAME,
  AGENT,
  SUPER_ADMIN,
  CLIENT,
  ADMIN,
  EXPERIENCE_TYPES,
  TOKEN_EXPIRATION,
} from 'constants';
import PageNotFound from '../pages/PageNotFound.jsx';
import ApplicantActions from '../components/ApplicantActions';
import ClientsActions from 'components/ClientsActions';
import { propertiesToShowOnColumns, BASE_MEDIA } from '../constants';
import Skeleton from 'react-loading-skeleton';
import { post, put } from './APIClient';
import { nanoid } from 'nanoid';

export const setDocumentTitle = (title = 'Dashboard') =>
  (document.title = `${title} | Swiftlee`);

export const setLocalStorage = (name, value) =>
  localStorage.setItem(name, JSON.stringify(value));

export const getLocalStorage = name => JSON.parse(localStorage.getItem(name));

export const removeFromLocalStorage = name => localStorage.removeItem(name);

export function removeSession() {
  removeFromLocalStorage(STORAGE_NAME);
  removeFromLocalStorage(TOKEN_EXPIRATION);
  location.reload();
}

export const passwordRules =
  '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$';

export const phoneNumber =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const getUserFromLocalStorage = () => getLocalStorage(STORAGE_NAME);

export function getValueFromLocalStorage(value) {
  const user = getUserFromLocalStorage();
  if (!user) return null;
  return user[value];
}

export const updateUserValueFromLocalStorage = (valueToUpdate, newValue) => {
  const user = getUserFromLocalStorage();
  setLocalStorage(STORAGE_NAME, { ...user, [valueToUpdate]: newValue });
};

export const isEmptyArray = array => {
  if (Array.isArray(array) && array.length) return false;
  else return true;
};

export const validateFileSize = (file, size = 5) => {
  if (file != null) {
    if (file.size / 1024 / 1024 > size) return false;
    else return true;
  }
};

export const logFormData = (data) => {
  for (const value of data.values()) {
    console.log(value);
  }
};

export const getImageOrientation = url => {
  let newImg = new Image();
  newImg.src = url;
  let orientation = '';
  if (newImg.width === newImg.height) orientation = 'square';
  if (newImg.width > newImg.height) orientation = 'landscape';
  if (newImg.width < newImg.height) orientation = 'portrait';
  return orientation;
};

export function renderPageByRole({
  AdminComponent = null | undefined,
  AgentComponent = null | undefined,
  ClientComponent = null | undefined,
  role,
  props,
}) {
  let pageByRole = null;
  switch (role) {
    case ADMIN:
    case SUPER_ADMIN: {
      if (AdminComponent) pageByRole = <AdminComponent {...props} />;
      else pageByRole = <PageNotFound />;
      break;
    }
    case AGENT: {
      if (AgentComponent) pageByRole = <AgentComponent {...props} />;
      else pageByRole = <PageNotFound />;
      break;
    }
    case CLIENT: {
      if (ClientComponent) pageByRole = <ClientComponent {...props} />;
      else pageByRole = <PageNotFound />;
      break;
    }
  }
  return pageByRole;
}

export function renderComponent({
  loading = true,
  Component = null | undefined,
  count = 5,
}) {
  if (loading) return <Skeleton count={count} />;
  else return <Component />;
}

// function to create the columns of the dataTable dynamically
export function fillColumnsDynamically(array, type) {
  const item = array[0]; // only need one item to know how many keys (will become columns) it contains
  const columns = [
    {
      label: '#',
      field: 'key',
      // sort: 'asc',
      width: 30,
    },
  ]; // columns array starts with Actions
  //iterate item to fill the columns values needed
  for (let key in item) {
    // check if key is in imported invalidKeys object that we don't want to show
    // since the propertiesToShowOnColumns object has valid/invalid keys, we use the "type" props passed from parent view to access dynamically to the required property
    const current = type.toLowerCase();
    if (!propertiesToShowOnColumns[current].invalid.includes(key)) {
      // following find inside the valid array for the prop name that match with current key ARRAY ORDER MATTERS!
      const founded = propertiesToShowOnColumns[current].valid.find(
        v => v.name === key
      );
      let label = founded.label;
      let width = founded.width;
      columns.push({
        label, // label shown on table head
        field: key, // value used to link each row property with the column assigned
        // sort: 'asc',
        width, // calculate the with of the column equitably
      });
    }
  }
  // add key column to enumerate
  columns.push({
    label: 'Actions',
    field: 'actions',
    // sort: 'asc',
    width: 150,
  });
  return columns;
}

// function that return an array with all the rows needed for dataTable state, receive the array and the openModal function
export function fillRowsDynamically({ arrayData, type, handleOpenNested }) {
  //   maps the array
  const rows = arrayData.map((item, key) => {
    // Inside of each item, there should be an object.
    // Iterate the object and add each (key: value) to the "row" to create each object of the row array dynamically
    let row = {};
    for (let prop in item) {
      row = {
        ...row,
        key: key + 1,
        [prop]: isDate(item[prop]) ? parseDate(item[prop]) : item[prop],
        actions:
          type === 'applicants' ? (
            <ApplicantActions handleOpenNested={handleOpenNested} item={item} />
          ) : (
            <ClientsActions handleOpenNested={handleOpenNested} item={item} />
          ), //passed as prop the item and the openModal function
      };
    }
    // return the object of the actual iteration
    return row;
  });

  return rows;
}

export const sortByValue = (array, value) =>
  array.slice().sort((a, b) => b[value] - a[value]);

export const isDate = date =>
  new Date(date) !== 'Invalid Date' && !isNaN(new Date(date));

export const parseDate = (
  date,
  options = {
    day: '2-digit',
    // hour: "2-digit",
    // hour12: false,
    // minute: "2-digit",
    month: 'short',
    year: 'numeric',
  }
) => new Date(date).toLocaleDateString('en-US', options);

export const date = new Date().toLocaleString('en-US', {
  // weekday: 'long',
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
});

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export function formatDate(date) {
  const parsed = new Date(date);
  return [
    parsed.getFullYear(),
    padTo2Digits(parsed.getMonth() + 1),
    padTo2Digits(parsed.getDate()),
  ].join('-');
}

export const today = new Date(date).toISOString().split('T')[0];

// convert object of objects into array of objects
export const objtsToArray = objOfObjs =>
  Object.entries(objOfObjs).map(e => ({ ...e[1], name: e[0] }));

// convert an array of objects into an object of objects
export const arrayObjtsToObj = (arr, key) =>
  Object.assign(
    {},
    ...arr.map(item => ({
      [item[key]]: item.data,
    }))
  );

export const handleAllResponses = (obj, names) =>
  arrayObjtsToObj(
    obj.map((res, key) => ({
      key: names[key],
      data: res.data,
    })),
    'key'
  );

export const buildFileURL = (url, filetype) => {
  if (!url) return '';
  if (url.includes('hireswiftlee'))
    return `${process.env.REACT_APP_SPACES_URL}/${filetype}/${url}`;
  else return `${process.env.REACT_APP_SPACES_URL}/old/${url}`;
};

const validFileTypes = [
  '.jpg',
  '.jpeg',
  '.png',
  '.pdf',
  '.doc',
  '.docx',
  '.webp',
];
export const checkFileType = file => {
  const fileType =
    validFileTypes
      .filter(type => file.toLowerCase().includes(type))[0]
      .replace('.', '') ?? false;

  return fileType;
};

export const existError = (response = []) =>
  response.some(r => (r.error ? true : false));

export const objsAreEqual = (obj1, obj2) =>
  JSON.stringify(obj1) === JSON.stringify(obj2);

export const isEmptyObject = obj => JSON.stringify(obj) === '{}';

export const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

// function to add "taken:false" prop to each skill item in "add new position modal" component
export const addTakenProp = items =>
  Object.entries(items).map(item => ({
    [item[0]]: item[1].map(s => ({ ...s, taken: false })),
  }));

export const updateTakenProp = (array, value, taken = true) =>
  array.map(s => (s.value == value ? { ...s, taken } : s));

// function to directly create an object of object from array of objects
export const arrToObj = arr =>
  Object.assign({}, ...arr.map(item => ({ ...item })));

export const parseSkillItems = (items, names) =>
  arrToObj(addTakenProp(items, names));

export const findSkillName = (items, value) => {
  const res = items.filter(i => i.value == value)[0].label;
  return res;
};

export const addIdToList = arr => {
  const res = arr.map(item => (!item.id ? { ...item, id: nanoid() } : item));
  return res;
};

/* this function update the taken skills when opening "add new skill modal"  */
export const updateTakensSkills = (currentSkills, type, items) => {
  const filter = currentSkills.filter(p =>
    items[type[2]].some(i => p[type[3]] == i.value || p[type[1]] == i.value)
  );
  const updated = items[type[2]].map(u =>
    filter.find(f => f[type[3]] == u.value || f[type[1]] == u.value)
      ? { ...u, taken: true }
      : u
  );
  return updated;
};

export const updateSkillPositions = ({
  newSkillArray,
  TYPE,
  campaign_position_id,
}) =>
  setPositions(
    positions.map(p =>
      p.id === campaign_position_id
        ? {
          ...positions.find(p => p.id === campaign_position_id),
          [TYPE]: newSkillArray,
        }
        : p
    )
  );

export const addNewCampaign = async ({ data, positions }) => {
  try {
    const res = await post({
      url: '/add-campaign',
      data,
    });

    if (res.status === 200 && !res.data.error) {
      // const positionResponse = []
      positions.forEach(async p => {
        const response = await postAddNewPosition({
          ...p,
          campaign_id: res.data.campaign_id,
        });
        // positionResponse.push(response)
      });

      return {
        data: {
          error: false,
          campaign_id: res.data.campaign_id,
        },
      };
    }
  } catch (error) {
    console.error('add campaign error', { error, response: error.response });
  }
};

export const postAddNewPosition = async ({
  description,
  activities_exp,
  industry_exp,
  name,
  skills_exp,
  vacancy,
  campaign_id,
}) => {
  try {
    const { data, status } = await post({
      url: '/add-campaign-position',
      data: {
        campaign_id,
        description,
        name,
        vacancy,
      },
    });
    if (status === 200 && !data.error) {
      const { campaign_position_id } = data;
      if (!isEmptyArray(activities_exp))
        activities_exp.forEach(
          async skill =>
            await addSkillPosition(
              campaign_position_id,
              skill,
              EXPERIENCE_TYPES.activities_exp
            )
        );

      if (!isEmptyArray(industry_exp))
        industry_exp.forEach(
          async skill =>
            await addSkillPosition(
              campaign_position_id,
              skill,
              EXPERIENCE_TYPES.industry_exp
            )
        );

      if (!isEmptyArray(skills_exp))
        skills_exp.forEach(
          async skill =>
            await addSkillPosition(
              campaign_position_id,
              skill,
              EXPERIENCE_TYPES.skills_exp
            )
        );

      return {
        data: {
          error: false,
        },
      };
    }
  } catch (error) {
    console.error('error on add position', { error, response: error.response });
    // return error.response
  }
};

export const addSkillPosition = async (campaign_position_id, skill, type) => {
  try {
    const skillType = type[2];
    return await post({
      url: `/position/${skillType}/add`,
      data: {
        campaign_position_id,
        years: skill.years,
        [type[3]]: skill[type[3]],
      },
    });
  } catch (error) {
    console.error('skill position error', { error, response: error.response });
    // return error
  }
};

export function createFormData(obj) {
  const formData = new FormData();
  Object.entries(obj).forEach(([key, value]) => {
    formData.append(key, value);
  });
  return formData;
}

export function removeTags(str) {
  if (!str) return;
  else str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, '');
}

export const wordCount = str => str.split(' ').length;

export const getCorrectWords = (invalid, words, paragraph) => {
  const errorWords = [...new Set(invalid.map(i => getWordAt(paragraph, i)))];
  return words - errorWords.length;
};

function getWordAt(str, pos) {
  // Perform type conversions.
  str = String(str);
  pos = Number(pos) >>> 0;

  // Search for the word's beginning and end.
  const left = str.slice(0, pos + 1).search(/\S+$/),
    right = str.slice(pos).search(/\s|[.,]/);

  // The last word in the string is a special case.
  if (right < 0) {
    const word = str.slice(left);
    return word;
  }

  const word = str.slice(left, right + pos);
  // Return the word, using the located bounds to extract it from the string.
  return word;
}

export function validateProfileCompleted(agent) {
  const {
    function_exp,
    industry_exp,
    information,
    skill_exp,
    typingTest,
    voiceTest,
  } = agent;

  if (
    isEmptyArray(function_exp) ||
    isEmptyArray(industry_exp) ||
    isEmptyArray(skill_exp) ||
    !typingTest ||
    isEmptyArray(voiceTest) ||
    !validateInfo(information)
  )
    return false;
  else return true;
}

const validateInfo = information =>
  valuesToValidate.map(v => information[v]).every(s => s);

const valuesToValidate = [
  'name',
  'last_name',
  'birth_date',
  'ine_path',
  'curp_path',
  'proof_address',
];

export const removeUnderscoreFromWord = str =>
  str && str.includes('_') ? capitalize(str.replaceAll('_', ' ')) : str;

export function subtractYears(numOfYears, date = new Date()) {
  date.setFullYear(date.getFullYear() - numOfYears);
  return date;
}

export const splitString = (str, separator) => str && str.split(separator);

export const markAsRead = async id => await put('/notify/update', { id });

export function locationDataObjExists(location) {
  if (
    (location.status === 'granted' || location.status === 'prompt') &&
    location.hasOwnProperty('data') &&
    location.data?.hasOwnProperty('latitude')
  )
    return true;
  return false;
}

export function isUsingSafariOnMac() {
  const userAgent = navigator.userAgent;

  if (
    userAgent.includes('Mac OS X') &&
    userAgent.includes('Safari') &&
    !userAgent.includes('Chrome')
  )
    return (
      <div>
        <p>
          If you're using mac, in order to avoid weird behavior, make sure you{' '}
          <a
            href="https://support.apple.com/guide/mac-help/allow-apps-to-detect-the-location-of-your-mac-mh35873/mac"
            target="_blank"
          >
            allow safari to detect the location of your Mac
          </a>
        </p>
        <p>
          Or, if you are using safari on iOS, please make sure you{' '}
          <a href="https://support.apple.com/en-us/HT207092">
            Turn on Location Services and GPS
          </a>{' '}
          on your device.
        </p>
      </div>
    );
}
