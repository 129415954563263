import React, { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  UncontrolledTooltip,
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
  Input,
  Button,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import {
  propertiesToShowOnColumns,
  FIELD_ID,
  ACCEPT,
  DELETE,
  SEARCH,
  BUTTON_ADD,
  REJECT,
} from '../constants';
import { isEmptyArray, isEmptyObject, removeTags, parseDate } from 'helpers';
import { MDBDataTableV5 } from 'mdbreact';

export function useTableData() {
  const [dataTable, setDataTable] = React.useState({});
  const [waiting, setWaiting] = React.useState(true);
  const [search, setSearch] = React.useState('');

  function setTableData({ array, type, actions, page, dispatch }) {
    if (isEmptyArray(array)) setDataTable({});
    else {
      setDataTable({
        columns: buildColumns({ array, type }),
        rows: buildRows({ array, type, actions, page, dispatch }),
      });
    }
  }

  const buildColumns = ({ array, type }) => {
    const item = array[0];
    const current = type.toLowerCase();
    const valids = propertiesToShowOnColumns[current].valid;

    const findValid = Object.keys(item).filter(
      i => !propertiesToShowOnColumns[current].invalid.includes(i)
    );

    const columns = [
      {
        label: '#',
        field: 'key',
        // sort: 'asc',
        width: 35,
      },
    ];

    findValid.forEach(v => {
      const item = valids.find(i => i.name === v);

      const label = item.label;
      const field = item.name;
      const width = item.width;
      columns.push({
        label, // label shown on table head
        field, // value used to link each row property with the column assigned
        // sort: 'asc',
        width, // calculate the with of the column equitably
      });
    });

    columns.push({
      label: 'Actions',
      field: 'actions',
      // sort: 'asc',
      width: 80,
    });

    return columns;
  };

  function buildRoute({ route, type, id, agent_id }) {
    if (route) {
      if (type === 'applicants') return `${route}/${agent_id}`;
      else return `${route}/${id}`;
    } else return '#';
  }

  const buildRows = ({ array, type, actions, page, dispatch }) =>
    array.map((item, idx) => {
      let row = {};
      Object.keys(item).forEach(prop => {
        const id = item[FIELD_ID[type]];
        row = {
          ...row,
          key: page ? page * 12 + (idx + 1) : idx + 1,
          id,
          [prop]: prop.toLowerCase().includes('date')
            ? !item[prop]
              ? 'null'
              : parseDate(item[prop])
            : !item[prop]
              ? 'null'
              : removeTags(item[prop]),
          actions: (
            <React.Fragment>
              <ul className="list-unstyled p-0 m-0 d-flex justify-content-start table-actions">
                {actions.map(({ type: actionType, route, icon, from }, index) => {
                  const className =
                    actionType === ACCEPT
                      ? 'text-success'
                      : actionType === DELETE || actionType === REJECT
                        ? 'text-danger'
                        : 'text-info';

                  return (
                    <li key={`${actionType}-${index}`} className="me-2">
                      <Link
                        to={buildRoute({
                          route,
                          type,
                          id,
                          agent_id: item.agent_fk,
                        })}
                        className={className}
                        onClick={route ? null : () => dispatch(actionType, row)}
                      >
                        <i className={`${icon}`} id={`${actionType}-${index}`}></i>
                        <UncontrolledTooltip
                          placement="top"
                          target={`${actionType}-${index}`}
                        >
                          {actionType}
                        </UncontrolledTooltip>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </React.Fragment>
          ),
        };
      });
      return row;
    });

  return {
    dataTable: useMemo(() => dataTable, [dataTable]),
    DataViewTable,
    search,
    setSearch,
    setTableData,
    setWaiting,
    waiting,
  };
}

export const DataViewTable = ({
  data,
  small = false,
  maxHeight = '50vh',
  button = null,
  handleSearch = () => { },
  loading = true,
  search = '',
  item = null,
  withSearch = true,
}) => (
  <Card>
    <CardBody>
      <Row className="mb-4">
        {withSearch && (
          <Col>
            <FormGroup>
              <Label for="search">Search {item && `by ${item}`}</Label>
              <InputGroup>
                <Input
                  id="search"
                  name="search"
                  placeholder={item ? `Search by ${item}` : 'Search'}
                  type="search"
                  onChange={({ target }) => handleSearch(SEARCH, target.value)}
                  value={search}
                />
                <Button outline color="primary" type="submit">
                  <i className="fas fa-search"></i>
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        )}
        <Col className="align-self-center text-end">
          {button && (
            <Link to={button.route ? button.route : '#'}>
              <Button
                color="primary"
                onClick={
                  button.route ? null : () => button.dispatch(BUTTON_ADD)
                }
              >
                {button.text}
              </Button>
            </Link>
          )}
        </Col>
      </Row>
      {loading ? (
        <Skeleton count={10} />
      ) : isEmptyObject(data) ? (
        <h2>No matches found</h2>
      ) : (
        <MDBDataTableV5
          bordered
          className="scrollbar-thin data-view-table"
          data={data}
          displayEntries={false}
          fixed
          hover
          maxHeight={maxHeight}
          paging={false}
          responsive
          scrollY
          searching={false}
          small={small}
          striped
          theadColor="bg-light"
        />
      )}
    </CardBody>
  </Card>
);
