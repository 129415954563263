import {
  GET_CAMPAIGNS,
  GET_CAMPAIGNS_SUCCESS,
  GET_CURRENT_CAMPAIGN,
  GET_CURRENT_CAMPAIGN_SUCCESS,
  CAMPAIGNS_ERROR,
  GET_CAMPAIGN_DETAIL,
  ADD_NEW_CAMPAIGN,
  ADD_CAMPAIGN_SUCCESS,
  ADD_CAMPAIGN_FAIL,
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_FAIL,
  DELETE_CAMPAIGN,
  DELETE_CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN_FAIL,
  GET_CAMPAIGN_DETAIL_FAIL,
  GET_CAMPAIGN_DETAIL_SUCCESS,
} from './actionTypes';

export const getCampaigns = params => ({
  type: GET_CAMPAIGNS,
  payload: params,
});
export const getCampaignsSuccess = campaigns => ({
  type: GET_CAMPAIGNS_SUCCESS,
  payload: campaigns,
});

export const getCurrentCampaign = campaign_id => ({
  type: GET_CURRENT_CAMPAIGN,
  payload: campaign_id,
});
export const getCurrentCampaignSuccess = ({
  currentCampaign,
  years,
  status,
}) => ({
  type: GET_CURRENT_CAMPAIGN_SUCCESS,
  payload: { currentCampaign, years, status },
});

export const addNewCampaign = campaign => ({
  type: ADD_NEW_CAMPAIGN,
  payload: campaign,
});

export const addCampaignSuccess = campaign => ({
  type: ADD_CAMPAIGN_SUCCESS,
  payload: campaign,
});

export const addCampaignFail = error => ({
  type: ADD_CAMPAIGN_FAIL,
  payload: error,
});

export const updateCampaign = campaign => ({
  type: UPDATE_CAMPAIGN,
  payload: campaign,
});

export const updateCampaignSuccess = campaign => ({
  type: UPDATE_CAMPAIGN_SUCCESS,
  payload: campaign,
});

export const updateCampaignFail = error => ({
  type: UPDATE_CAMPAIGN_FAIL,
  payload: error,
});

export const deleteCampaign = campaign => ({
  type: DELETE_CAMPAIGN,
  payload: campaign,
});

export const deleteCampaignSuccess = campaign => ({
  type: DELETE_CAMPAIGN_SUCCESS,
  payload: campaign,
});

export const deleteCampaignFail = error => ({
  type: DELETE_CAMPAIGN_FAIL,
  payload: error,
});

export const getCampaignDetail = campaignId => ({
  type: GET_CAMPAIGN_DETAIL,
  campaignId,
});

export const getCampaignDetailSuccess = campaignDetails => ({
  type: GET_CAMPAIGN_DETAIL_SUCCESS,
  payload: campaignDetails,
});

export const getCampaignDetailFail = error => ({
  type: GET_CAMPAIGN_DETAIL_FAIL,
  payload: error,
});

export const campaignsError = error => ({
  type: CAMPAIGNS_ERROR,
  payload: error,
});
