export { useLoad } from './useLoad.tsx';
export { useNotification } from './useNotification';
export { useNotifications } from './useNotifications';
export { useConfirmation } from './useConfirmation';
export { useModal } from './useModal';
export { useCompressFile } from './useCompressFile';
export { useAuth } from './useAuth';
export { useRole } from './useRole';
export { useGeolocation } from './useGeolocation';
export * from './useTableData.jsx';

export const variants = {
  Accept: 'info',
  Error: 'danger',
  Info: 'info',
  Loading: 'loading',
  Reject: 'danger',
  Success: 'success',
};
