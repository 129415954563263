import React from 'react';
import { Modal, ModalHeader, ModalBody, Button, Spinner } from 'reactstrap';
import { variants } from 'hooks';

export function Notification({ isOpen, close, variant, message }) {
  const duration = 2000;

  React.useEffect(() => {
    getIcon(variant);
    if (variant === variants.Success || variant === variants.Reject)
      setTimeout(() => {
        close();
      }, duration);
  }, [variant]);

  function getIcon() {
    if (variant === variants.Loading)
      return {
        icon: <Spinner size="sm">Loading...</Spinner>,
        color: 'info',
      };
    else if (variant === variants.Success || variant === variants.Reject)
      return {
        icon: 'mdi-check-bold',
        color: variant,
      };
    else if (variant === variants.Error)
      return {
        icon: 'mdi-close-thick',
        color: variant,
      };
    else
      return {
        icon: 'mdi-information-outline',
        color: variant,
      };
  }

  const canClose =
    variant === variants.Success ||
    variant === variants.Reject ||
    variant === variants.Error;

  return (
    <Modal
      size="sm"
      isOpen={isOpen}
      backdrop={canClose ?? 'static'}
      toggle={canClose ? close : null}
      className="__notification"
    >
      <ModalHeader toggle={canClose ? close : null}>
        <i className={`mdi ${getIcon().icon} text-${variant}`}></i>{' '}
        {variant && <span className={`text-${variant}`}>Done</span>}
      </ModalHeader>
      <ModalBody className="text-center">
        <h4>{message}</h4>
        {canClose && (
          <Button outline color="dark" className="mt-3" onClick={close}>
            Close
          </Button>
        )}
      </ModalBody>
    </Modal>
  );
}
