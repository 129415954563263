import React from 'react';
import {
  get,
  getValueFromLocalStorage,
  markAsRead,
  setLocalStorage,
} from 'helpers';
import { notificationTypes, notificationStatus } from 'constants';
import { useLoad } from 'hooks';
import { useHistory } from 'react-router-dom';
import { Context } from 'context/providers';

const fiveMinutes = 300000;
const by = 15;

export const useNotifications = () => {
  const history = useHistory();
  const [{ notifications }, { saveNotifications, addMoreNotifications }] =
    React.useContext(Context);

  const { actual_page, total_pages } = notifications;

  const {
    NEW_CAMPAIGN,
    NEW_AGENT,
    NEW_APPLICANT,
    CAMPAIGN_APPROVED,
    CAMPAIGN_LOCKED,
    CAMPAIGN_DISABLED,
    APPLICANT_APPROVED,
  } = notificationTypes;

  const { data, loading, loaded, loadError, fetchData } = useLoad();
  const {
    data: more,
    loading: moreLoading,
    loaded: moreLoaded,
    loadError: moreError,
    fetchData: fetchMore,
  } = useLoad();

  const startNotificationsInterval = () => {
    getNotifications();
    setTimeout(() => {
      setInterval(getNotifications, fiveMinutes);
    }, fiveMinutes);
  };

  const getNotifications = (page = 0, by = 15) =>
    fetchData(() => retrieveNotifications(page, by));

  const loadMoreNotifications = () => {
    fetchMore(() => retrieveNotifications(Number(actual_page) + 1, by));
  };

  const retrieveNotifications = async (page = 0, by = 15) =>
    await get('/notify/all', {
      user: getValueFromLocalStorage('id'),
      page,
      by,
    });

  React.useEffect(() => {
    if (loaded && !loading && !loadError && !data.data.false)
      saveNotifications(data.data);
  }, [data, loading, loaded, loadError]);

  React.useEffect(() => {
    if (moreLoaded && !moreLoading && !moreError && !more.data.false)
      addMoreNotifications(more.data);
  }, [more, moreLoading, moreLoaded, moreError]);

  const unread = notifications.data.filter(
    r => r.status === notificationStatus.UNREAD
  );

  const last5Notifications = unread.slice(0, 5);

  const handleNotification = async notification => {
    const { notify_type_id, notify_id, campaign_id, agent_id } = notification;

    await markAsRead(notify_id);

    switch (notify_type_id) {
      case NEW_CAMPAIGN:
      case CAMPAIGN_APPROVED:
      case CAMPAIGN_LOCKED:
      case CAMPAIGN_DISABLED: {
        history.push(`/campaign/${campaign_id}/edit`);
        break;
      }
      case NEW_AGENT: {
        history.push(`/agents/${agent_id}`);
        break;
      }
      case NEW_APPLICANT: {
        setLocalStorage('agent_id', agent_id);
        history.push('/agent-profile');
        break;
      }
      default: // this is when CAMPAIGN_ERASED, will get notifications again to update view
        getNotifications();
        break;
    }
  };

  return {
    getNotifications,
    handleNotification,
    last5Notifications,
    loadMoreNotifications,
    notifications: notifications.data,
    startNotificationsInterval,
    unread,
    moreLoading,
    showMoreButton: total_pages - 1 !== Number(actual_page),
  };
};
