import { get, getValueFromLocalStorage } from 'helpers';
import { getAllAdmins } from './admins';

export const getDataWhenAdminLogin = async user_type =>
  await Promise.all([
    get('/items/status'),
    get('/items/years'),
    get('/getIndustryItems'),
    get('/getFunctionItems'),
    get('/getSkillItems'),
    get('/clients_items'),
    getAllAdmins(),
    getCampaignsData({ user_type }),
  ])
    .then(response => response)
    .catch(error =>
      console.error('error on function getDataWhenAdminLogin: ', error)
    );

export const getDataWhenClientLoad = async () => await getDataWhenAgentLogin();

export const getDataWhenAgentLogin = async () =>
  await Promise.all([
    get('/items/status'),
    get('/items/years'),
    get('/getIndustryItems'),
    get('/getFunctionItems'),
    get('/getSkillItems'),
  ])
    .then(response => response)
    .catch(error => console.error('error: ', error));

export const getCampaignData = async campaign_id =>
  await get('/campaign-detail', { id: campaign_id });

export const getCampaignAgents = async campaign_id =>
  await get('/campaign/workers', { id: campaign_id });

export const getCampaignsData = async ({
  by = 12,
  client_id = -1,
  page = 0,
  search = '',
  date_from = '',
  date_to = new Date().toISOString().split('T')[0],
  status = 0,
  user_type,
}) =>
  await get('/all-campaigns', {
    by,
    client_id: client_id !== -1 ? client_id : null,
    page: page.toString(),
    search,
    date_from,
    date_to,
    status,
    user_type,
  });

export const getCampaignsByClientId = async ({
  id,
  by = 12,
  page = 0,
  search = '',
  date_from = '',
  date_to = new Date().toISOString().split('T')[0],
  status = 1,
}) =>
  await get('/campaigns/client', {
    id,
    page,
    by,
    search,
    date_from,
    date_to,
    status,
  });

export const getPositionsByAgent = async ({
  by = 12,
  agent_fk = getValueFromLocalStorage('agent_id'),
  page = 0,
  search = '',
  date_from = '',
  date_to = new Date().toISOString().split('T')[0],
}) =>
  await get('/positions', {
    by,
    agent_fk,
    date_from,
    date_to,
    page,
    search,
  });
