import React from 'react';
import { footerLinks } from 'constants';
import { Container, Row, Col } from 'reactstrap';

const Footer = () => {
  return (
    <React.Fragment>
      <footer className='footer-not-logged'>
        <Container fluid={true}>
          <Row>
            <Col>
              <div className="d-flex justify-content-end gap-3">
                {
                  footerLinks.map(({ text, link, value, id }) => (
                    <p key={id}>
                      {text} <a href={link} target="_blank">{value}</a>
                    </p>
                  ))
                }
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
