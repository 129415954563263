export const CSC_DOCUMENT_TITLE = 'Swiftlee';
export const STATUS_SUCCESS = 200;
export const STATUS_ERROR = 400;
export const STATUS_FAILED = 500;
export const NO_ERROR = 0;

// user types id
export const CURRENT_USER_TYPE = 'CSC_CURRENT_USER';
export const USER_TYPE_ADMIN = 1;
export const USER_TYPE_AGENT = 3;
export const USER_TYPE_CLIENT = 2;
export const USER_TYPE_SUPER_ADMIN = 4;

export const ADMIN = 'ADMIN';
export const AGENT = 'AGENT';
export const CLIENT = 'CLIENT';
export const SUPER_ADMIN = 'SUPER_ADMIN';

export const USER_TYPES = {
  ADMIN: USER_TYPE_ADMIN,
  AGENT: USER_TYPE_AGENT,
  CLIENT: USER_TYPE_CLIENT,
  SUPER_ADMIN: USER_TYPE_SUPER_ADMIN,
};

// notification types id
export const notificationTypes = {
  NEW_CAMPAIGN: 1,
  NEW_AGENT: 2,
  NEW_APPLICANT: 3,
  CAMPAIGN_APPROVED: 4,
  CAMPAIGN_LOCKED: 5,
  CAMPAIGN_DISABLED: 6,
  CAMPAIGN_ERASED: 7,
  APPLICANT_APPROVED: 8,
};

export const notificationStatus = {
  UNREAD: 1,
  SEEN: 2,
};

// campaign id status
export const CAMPAIGN_ACTIVE = 1;
export const CAMPAIGN_PENDING = 2;
export const CAMPAIGN_DISABLED = 3;
export const CAMPAIGN_LOCKED = 4;

export const campaignStatus = [
  { name: 'ALL', value: 0 },
  { name: 'ACTIVE', value: CAMPAIGN_ACTIVE },
  { name: 'PENDING', value: CAMPAIGN_PENDING },
  { name: 'DISABLED', value: CAMPAIGN_DISABLED },
  { name: 'LOCKED', value: CAMPAIGN_LOCKED },
];

export const MATCH_APPLY = 'MATCH_APPLY';
export const NO_MATCH_APPLY = 'NO_MATCH_APPLY';

export const STORAGE_NAME = 'user-data-csc';
export const CSC_TEMP_STORE = 'csc-temp-store';
export const TOKEN_EXPIRATION = 'x-access-token-expiration';

// properties to show/not show in data table component
export const propertiesToShowOnColumns = {
  /*********  CLIENTS *********/
  clients: {
    invalid: [
      'client_id',
      'line_business_fk',
      'user_fk',
      'representative_email',
      'representative_position',
      'representative_phone',
    ],
    valid: [
      { name: 'company_name', label: 'Company', width: null },
      { name: 'representative_name', label: 'Representative', width: null },
      { name: 'description', label: 'Description', width: null },
      { name: 'status', label: 'Status', width: 100 },
    ],
  },
  /*********  CAMPAIGNS *********/
  campaigns: {
    invalid: ['campaign_id', 'client_id', 'status_fk'],
    valid: [
      { name: 'campaign', label: 'Campaign Name', width: 100 },
      { name: 'created', label: 'Created', width: 100 },
      { name: 'client', label: 'Client', width: 100 },
      { name: 'status_name', label: 'Status', width: 100 },
      { name: 'positions', label: 'Positions', width: 100 },
    ],
  },
  /*********  APPLICANTS *********/
  applicants: {
    invalid: [
      'applicant_id',
      'agent_fk',
      'campaign_position_fk',
      'applicant_status',
    ],
    valid: [
      { name: 'agent_name', label: 'Name', width: 90 },
      { name: 'agent_last_name', label: 'Last Name', width: 90 },
      { name: 'position_name', label: 'Position Name', width: 170 },
      { name: 'applicant_date', label: 'Applicant Date', width: 100 },
      { name: 'status', label: 'Status', width: 60 },
    ],
  },
  /*********  AGENTS *********/
  agents: {
    invalid: ['agent_id', 'user_fk', 'status_fk', 'image_path', 'description'],
    valid: [
      { name: 'name', label: 'Name', width: 90 },
      { name: 'last_name', label: 'Last Name', width: 90 },
      { name: 'birth_date', label: 'Birth Date', width: 50 },
    ],
  },
  /***************** ADMINS **************/
  admin: {
    invalid: ['user_id', 'password', 'image_path', 'user_type_id', 'status_id'],
    valid: [
      { name: 'name', label: 'Name', width: 50 },
      { name: 'last_name', label: 'Last Name', width: 70 },
      { name: 'email', label: 'Email', width: 150 },
      { name: 'phone', label: 'Phone', width: 70 },
      { name: 'creation_date', label: 'Creation Date', width: 70 },
      { name: 'user_type', label: 'Level', width: 50 },
      { name: 'status', label: 'Status', width: 50 },
    ],
  },
};
export const FIELD_ID = {
  clients: 'user_fk',
  agents: 'agent_id',
  applicants: 'applicant_id',
};

export const VIEW_DETAILS = 'VIEW_DETAILS';
export const ACCEPT = 'ACCEPT';
export const REJECT = 'REJECT';
export const DELETE = 'DELETE';
export const EDIT = 'EDIT';

export const EXPERIENCE_TYPES = {
  industry_exp: [
    'industry_experience_id',
    'industry_id',
    'industry',
    'industry_fk',
  ],
  activities_exp: [
    'activities_experience_id',
    'function_performed_id',
    'activity',
    'function_performed_fk',
  ],
  skills_exp: ['skill_experience_id', 'skill_id', 'skill', 'skill_fk'],
};

export const SKILL_TYPE = {
  activity: 'activities_exp',
  industry: 'industry_exp',
  skill: 'skills_exp',
};

export const ADMIN_TYPES = [
  { value: 1, label: 'Admin' },
  { value: 4, label: 'Super Admin' },
];

export const OPEN_ADD_SKILL = 'OPEN_ADD_SKILL';
export const ADD_CAMPAIGN = 'ADD_CAMPAIGN';
export const ADD_POSITION = 'ADD_POSITION';
export const ADD_SKILL = 'ADD_SKILL';
export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';
export const DELETE_POSITION = 'DELETE_POSITION';
export const DELETE_SKILL = 'DELETE_SKILL';
export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN';
export const UPDATE_POSITION = 'UPDATE_POSITION';
export const UPDATE_SKILL = 'UPDATE_SKILL';

export const SUCCESS = 'success';
export const DANGER = 'danger';
export const ERROR = 'error';

export const MESSAGES = {
  delete:
    'This action will perform a deletion and can\'t be undone. Do you want to continue?',
  acceptApplicant: 'You\'re about to accept this applicant, continue?',
  rejectApplicant: 'You\'re about to reject this applicant, continue?',
};

export const CREATE_POSITIONS = 'CREATE_POSITIONS';
export const SEARCH = 'SEARCH';
export const BUTTON_ADD = 'BUTTON_ADD';

export const REGISTER_VALUES = 'REGISTER_VALUES';

export const paragraphs = [
  'During the first part of your life, you only become aware of happiness once you have lost it. Then an age comes, a second one, in which you already know, at the moment when you begin to experience true happiness, that you are, at the end of the day, going to lose it. When I met Belle, I understood that I had just entered this second age. I also understood that I hadn’t reached the third age, in which anticipation of the loss of happiness prevents you from living.',
  'Dave watched as the forest burned up on the hill, only a few miles from her house. The car had been hastily packed and Marta was inside trying to round up the last of the pets. Dave went through his mental list of the most important papers and documents that they couldn\'t leave behind. He scolded himself for not having prepared these better in advance and hoped that he had remembered everything that was needed. He continued to wait for Marta to appear with the pets, but she still was nowhere to be seen.',
  'He sat across from her trying to imagine it was the first time. It wasn\'t. Had it been a hundred? It quite possibly could have been. Two hundred? Probably not. His mind wandered until he caught himself and again tried to imagine it was the first time.',
  'He wondered if he should disclose the truth to his friends. It would be a risky move. Yes, the truth would make things a lot easier if they all stayed on the same page, but the truth might fracture the group leaving everything in even more of a mess than it was not telling the truth. It was time to decide which way to go.',
  'It was easy to spot her. All you needed to do was look at her socks. They were never a matching pair. One would be green while the other would be blue. One would reach her knee while the other barely touched her ankle. Every other part of her was perfect, but never the socks. They were her micro act of rebellion.',
  'He heard the crack echo in the late afternoon about a mile away. His heart started racing and he bolted into a full sprint. \'It wasn\'t a gunshot, it wasn\'t a gunshot.\' he repeated under his breathlessness as he continued to sprint.',
];
export const speakingTestInstructions = [
  'Please record your voice sample.',
  'This are used by clients to assess your communication skills before hiring you.',
  'You can introduce yourself and speak about your experience or simply read the paragraph sample.',
  'You can change the paragraph by clicking the \'change paragraph\' option.',
  'When finish, you can play back your sample to make sure it sounds good.',
  'If needed, you can restart the record by clicking the mic button.',
  'Don\'t forget to save the audio by clicking the save button.',
];

export const typingTestParagraphs = [
  {
    quote:
      'You can fool all of the people some of the time, and some of the people all of the time, but you can\'t fool all of the people all of the time.',
    author: 'Abraham Lincoln',
  },
  {
    quote:
      'The whole secret of a successful life is to find out what is one\'s destiny to do, and then do it. When everything seems to be going against you, remember that the airplane takes off against the wind, not with it.',
    author: 'Henry Ford',
  },
  {
    quote:
      'I have a dream that my four little children will one day live in a nation where they will not be judged by the color of their skin but by the content of their character.',
    author: 'Martin Luther King',
  },
  {
    quote:
      'Successful people do what unsuccessful people are not willing to do. Don\'t wish it were easier; wish you were better. If you don\'t design your own life plan, chances are you\'ll fall into someone else\'s plan. And guess what they have planned for you? Not much.',
    author: 'Jim Rohn',
  },
  {
    quote:
      'If a man is called to be a street sweeper, he should sweep streets even as a Michaelangelo painted, or Beethoven composed music or Shakespeare wrote poetry. He should sweep streets so well that all the hosts of heaven and earth will pause to say, \'Here lived a great street sweeper who did his job well.',
    author: 'Martin Luther King',
  },
  {
    quote:
      'Owning our story can be hard but not nearly as difficult as spending our lives running from it. Embracing our vulnerabilities is risky but not nearly as dangerous as giving up on love and belonging and joy—the experiences that make us the most vulnerable. Only when we are brave enough to explore the darkness will we discover the infinite power of our light.',
    author: 'Brene Brown',
  },
  {
    quote:
      'Every day, think as you wake up, today I am fortunate to be alive, I have a precious human life, I am not going to waste it. I am going to use all my energies to develop myself, to expand my heart out to others; to achieve enlightenment for the benefit of all beings.',
    author: 'The Dalai Lama',
  },
  {
    quote:
      'Life is too short to waste any amount of time on wondering what other people think about you. In the first place, if they had better things going on in their lives, they wouldn\'t have the time to sit around and talk about you. What\'s important to me is not others\' opinions of me, but what\'s important to me is my opinion of myself.',
    author: ' C. JoyBell C',
  },
  {
    quote:
      'If you can keep your head when all about you Are losing theirs and blaming it on you, If you can trust yourself when all men doubt you, But make allowance for their doubting too; If you can wait and not be tired by waiting, Or being lied about, don\'t deal in lies, Or being hated, don\'t give way to hating, And yet don\'t look too good, nor talk too wise',
    author: 'Rudyard Kipling',
  },
  {
    quote:
      'As long as people have been on this earth, the moon has been a mystery to us. Think about it. She is strong enough to pull the oceans, and when she dies away, she always comes back again. My mama used to tell me Our Lady lived on the moon and that I should dance when her face was bright and hibernate when it was dark.',
    author: 'Sue Monk Kidd',
  },
  {
    quote:
      'Every form has its own meaning. Every man creates his meaning and form and goal. Why is it so important - what others have done? Why does it become sacred by the mere fact of not being your own? Why is anyone and everyone right - so long as it\'s not yourself? Why does the number of those others take the place of truth? Why is truth made a mere matter of arithmetic - and only of addition at that?',
    author: 'Ayn Rand',
  },
  {
    quote:
      'What I like about cooking is that, so long as you follow the recipe exactly, everything always turns out perfect. It\'s too bad there\'s no recipe for happiness. Happiness is more like pastry—which is to say that you can take pains to keep cool and not overwork the dough, but if you don\'t have that certain light touch, your best efforts still fall flat.',
    author: 'Josh Lanyon',
  },
  {
    quote:
      'Sometimes I feel like I don\'t belong anywhere, & it\'s gonna take so long for me to get to somewhere, Sometimes I feel so heavy hearted, but I can\'t explain cuz I\'m so guarded. But that\'s a lonely road to travel, and a heavy load to bear. And it\'s a long, long way to heaven but I gotta get there Can you send an angel? Can you send me an angel...to guide me.',
    author: 'Alicia Keys',
  },
  {
    quote:
      'A human being is a part of the whole called by us universe, a part limited in time and space. He experiences himself, his thoughts and feeling as something separated from the rest, a kind of optical delusion of his consciousness. This delusion is a kind of prison for us, restricting us to our personal desires and to affection for a few persons nearest to us.',
    author: 'Albert Einstein',
  },
  {
    quote:
      'The important thing is not to stop questioning. Curiosity has its own reason for existence. One cannot help but be in awe when he contemplates the mysteries of eternity, of life, of the marvelous structure of reality. It is enough if one tries merely to comprehend a little of this mystery each day.',
    author: 'Albert Einstein',
  },
  {
    quote:
      'Pain is a pesky part of being human, I\'ve learned it feels like a stab wound to the heart, something I wish we could all do without, in our lives here. Pain is a sudden hurt that can\'t be escaped. But then I have also learned that because of pain, I can feel the beauty, tenderness, and freedom of healing.',
    author: 'C. JoyBell C',
  },
  {
    quote:
      'All that we are is the result of what we have thought: it is founded on our thoughts and made up of our thoughts. If a man speak or act with an evil thought, suffering follows him as the wheel follows the hoof of the beast that draws the wagon.... If a man speak or act with a good thought, happiness follows him like a shadow that never leaves him.',
    author: 'Gautama Buddha',
  },
  {
    quote:
      'Man often becomes what he believes himself to be. If I keep on saying to myself that I cannot do a certain thing, it is possible that I may end by really becoming incapable of doing it. On the contrary, if I have the belief that I can do it, I shall surely acquire the capacity to do it even if I may not have it at the beginning.',
    author: 'Mahatma Gandhi',
  },
  {
    quote:
      'I said to my soul, be still and wait without hope, for hope would be hope for the wrong thing; wait without love, for love would be love of the wrong thing; there is yet faith, but the faith and the love are all in the waiting. Wait without thought, for you are not ready for thought: So the darkness shall be the light, and the stillness the dancing.',
    author: 'T.S. Eliot',
  },
];

export const footerLinks = [
  {
    id: 'copyright',
    text: `${new Date().getFullYear()} ©`,
    link: 'https://hireswiftlee.com/',
    value: 'Swiftlee.'
  },
  {
    id: 'privacy-policy',
    text: '',
    link: 'https://hireswiftlee.com/privacy-policy/',
    value: 'Privacy Policy'
  },
  {
    id: 'meraki',
    text: 'Designed & Developed by',
    link: 'https://merakimx.com',
    value: 'Meraki Mx.'
  },
];