import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Routes from './routes';
import { getUserFromLocalStorage, getValueFromLocalStorage } from './helpers';
import { useRole, useAuth } from './hooks';

// Import scss
import './assets/scss/theme.scss';
import './assets/scss/custom-classes.scss';

const App = props => {
  const { role } = useRole();
  const { fetchDataByRole } = useAuth();
  const token = getValueFromLocalStorage('token');

  React.useEffect(() => {
    if (role && token) fetchDataByRole(role, token);
  }, []);

  return <Routes {...props} user={getUserFromLocalStorage()} />;
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = state => {
  return { layout: state.Layout };
};

export default connect(mapStateToProps, null)(App);
