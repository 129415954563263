import { getValueFromLocalStorage } from 'helpers';
import {
  USER_TYPE_AGENT,
  USER_TYPE_CLIENT,
  USER_TYPE_ADMIN,
  AGENT,
  CLIENT,
  ADMIN,
  SUPER_ADMIN,
  USER_TYPE_SUPER_ADMIN,
} from 'constants';

export const useRole = () => {
  const roles = {
    [USER_TYPE_AGENT]: AGENT,
    [USER_TYPE_CLIENT]: CLIENT,
    [USER_TYPE_ADMIN]: ADMIN,
    [USER_TYPE_SUPER_ADMIN]: SUPER_ADMIN,
  };

  const getRole = user_type => roles[user_type];

  const names = {
    [AGENT]: 'name',
    [ADMIN]: '',
    [CLIENT]: 'company_name',
  };
  const role = roles[getValueFromLocalStorage('user_type')];

  const userName = getValueFromLocalStorage(names[role]);

  return {
    getRole,
    role,
    userName,
  };
};
