import {
  GET_CAMPAIGNS,
  GET_CAMPAIGNS_SUCCESS,
  GET_CURRENT_CAMPAIGN,
  GET_CURRENT_CAMPAIGN_SUCCESS,
  CAMPAIGNS_ERROR,
  GET_CAMPAIGN_DETAIL,
  ADD_NEW_CAMPAIGN,
  ADD_CAMPAIGN_SUCCESS,
  ADD_CAMPAIGN_FAIL,
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_FAIL,
  DELETE_CAMPAIGN,
  DELETE_CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN_FAIL,
  GET_CAMPAIGN_DETAIL_FAIL,
  GET_CAMPAIGN_DETAIL_SUCCESS,
} from './actionTypes';

const INIT_STATE = {
  campaigns: [],
  total_pages: 1,
  actual_page: 1,
  error: '',
  loading: false,
  currentCampaign: {},
};

const Campaigns = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CAMPAIGNS: {
      return { ...state, loading: true };
    }
    case GET_CAMPAIGNS_SUCCESS: {
      const { campaigns, total_pages, actual_page } = action.payload;
      return {
        ...state,
        campaigns: campaigns,
        total_pages: total_pages,
        actual_page,
        loading: false,
      };
    }

    case GET_CURRENT_CAMPAIGN: {
      return { ...state, loading: true };
    }
    case GET_CURRENT_CAMPAIGN_SUCCESS: {
      const { currentCampaign, years, status } = action.payload;
      return { ...state, loading: false, currentCampaign, years, status };
    }

    case ADD_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: [...state.campaigns, action.payload],
      };

    case ADD_CAMPAIGN_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_CAMPAIGN_DETAIL_SUCCESS:
      return {
        ...state,
        campaignDetail: action.payload,
      };

    case UPDATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: state.campaigns.map(campaign =>
          campaign.id.toString() === action.payload.id.toString()
            ? { campaign, ...action.payload }
            : campaign
        ),
      };

    case UPDATE_CAMPAIGN_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: state.campaigns.filter(
          campaign => campaign.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_CAMPAIGN_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_CAMPAIGN_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case CAMPAIGNS_ERROR: {
      return { ...state, error: action.payload, loading: false };
    }

    default:
      return state;
  }
};

export default Campaigns;
