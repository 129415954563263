import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import intlFormatDistance from 'date-fns/intlFormatDistance';
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { isEmptyArray } from 'helpers';
import { useNotifications } from 'hooks';

const NotificationDropdown = () => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const {
    startNotificationsInterval,
    handleNotification,
    last5Notifications,
    unread,
  } = useNotifications();

  React.useEffect(() => {
    startNotificationsInterval();
  }, []);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i
            className={`bx bx-bell ${
              !isEmptyArray(last5Notifications) && 'bx-tada'
            }`}
          />
          {unread.length > 0 && (
            <span className="badge bg-danger rounded-pill">
              {unread.length >= 10 ? '+9' : unread.length}
            </span>
          )}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0">Notifications</h6>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ height: '230px' }}>
            {last5Notifications.map(n => (
              <a
                key={n.notify_id}
                onClick={() => handleNotification(n)}
                className="text-reset notification-item"
              >
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <h6 className="mt-0 mb-1">{n.notify_type}</h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">
                        Campaign: <strong>{n.campaign_name}</strong>
                      </p>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline me-1" />
                        {intlFormatDistance(new Date(n.created_at), new Date())}
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            ))}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 text-center"
              to="/notifications"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>{' '}
              <span key="t-view-more">View All...</span>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default NotificationDropdown;
