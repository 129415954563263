import React from 'react';
import { DANGER } from 'constants';

export function useModal() {
  const [modalVariant, setModalVariant] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [modalMessage, setModalMessage] = React.useState('');
  const [type, setType] = React.useState(DANGER);
  const [data, setData] = React.useState(null);
  const [reload, setReload] = React.useState(false);

  const close = () => {
    setIsOpen(false);
    setReload(true);
    setData(null);
    setTimeout(() => {
      setModalVariant(null);
      setModalMessage('');
    }, 500);
  };

  const setModal = (variant, message, type = DANGER) => {
    if (Boolean(variant) && Boolean(message)) setIsOpen(true);
    setModalVariant(variant);
    setModalMessage(message);
    setType(type);
  };

  const openModal = (values = true) => {
    if (values !== true && values) {
      setData(values);
    }
    setIsOpen(true);
    setReload(false);
  };

  return {
    variant: modalVariant,
    isOpen,
    advise: modalMessage,
    close,
    type,
    modalData: data,
    openModal,
    setModal,
    reload,
  };
}
