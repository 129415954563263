import { get, post, setLocalStorage } from 'helpers';
import {
  STATUS_SUCCESS,
  NO_ERROR,
  STORAGE_NAME,
  TOKEN_EXPIRATION,
} from 'constants';

export async function loginUser(user) {
  try {
    const { status, data } = await post({
      url: '/auth',
      data: {
        email: user.email,
        password: user.password,
        userType: user.userType,
      },
    });
    if (status === STATUS_SUCCESS) {
      if (data.error !== NO_ERROR) return { error: true, data: data.msg };
      else if (data.auth) {
        setLocalStorage(STORAGE_NAME, data);
        setLocalStorage(TOKEN_EXPIRATION, Date.now() + 2 * 60 * 60 * 1000);
        return { error: false, data };
      }
    }
  } catch (error) {
    console.error('error on login: ', error);
    return { error: true, data: error };
  }
}
