import React from 'react';
import { Notification } from 'components/Notification';

export function useNotification() {
  const [notificationVariant, setNotificationVariant] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [notificationMessage, setNotificationMessage] = React.useState('');
  const close = () => {
    setIsOpen(false);
    setTimeout(() => {
      setNotificationVariant(null);
      setNotificationMessage('');
    }, 500);
  };

  const setNotification = (variant, message) => {
    if (Boolean(variant) && Boolean(message)) setIsOpen(true);
    else closeNotification();
    setNotificationVariant(variant);
    setNotificationMessage(message);
  };

  const closeNotification = () => {
    setNotificationVariant(null);
    setNotificationMessage('');
    setTimeout(() => {
      setIsOpen(false);
    }, 1000);
  };

  return {
    variant: notificationVariant,
    isOpen,
    message: notificationMessage,
    close,
    setNotification,
    Notification,
  };
}
