import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import { authProtectedRoutes, publicRoutes } from './routes';
import { ValidatePermissions } from 'hoc';
import VerticalLayout from '../components/VerticalLayout/';
import NonAuthLayout from '../components/NonAuthLayout';
import Footer from 'components/VerticalLayout/Footer';

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  user,
  roles,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthProtected && !user)
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          );
        else if (!isAuthProtected && user)
          return (
            <Redirect
              to={{ pathname: '/home', state: { from: props.location } }}
            />
          );

        if (!isAuthProtected)
          return (
            <Layout>
              <Component {...props} />
              <Footer logged={false} />
            </Layout>
          );

        return (
          <ValidatePermissions roles={roles}>
            <Layout>
              <Component {...props} />
            </Layout>
          </ValidatePermissions>
        );
      }}
    />
  );
};

const Routes = ({ layout, user }) => {
  return (
    <Router>
      <Suspense fallback={<div></div>}>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              user={user}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              layout={VerticalLayout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              user={user}
              exact
              roles={route.roles}
            />
          ))}
        </Switch>
      </Suspense>
    </Router>
  );
};

AppRoute.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export default Routes;
