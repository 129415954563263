import React from 'react';
import { Redirect } from 'react-router-dom';
import { SUPER_ADMIN, ADMIN, CLIENT, AGENT } from 'constants';

// Authentication related pages
const AdminLogin = React.lazy(() =>
  import('../pages/Authentication/AdminLogin')
);
const Login = React.lazy(() => import('../pages/Authentication/Login'));
const ForgetPwd = React.lazy(() =>
  import('../pages/Authentication/ForgetPassword')
);
// Profile
const UserProfile = React.lazy(() =>
  import('../pages/Authentication/user-profile')
);
// Inner Authentication
const Register = React.lazy(() => import('pages/Authentication/register/'));
const Recoverpw = React.lazy(() =>
  import('../pages/AuthenticationInner/Recoverpw')
);
const Recoverpw2 = React.lazy(() =>
  import('../pages/AuthenticationInner/Recoverpw2')
);
const ForgetPwd1 = React.lazy(() =>
  import('../pages/AuthenticationInner/ForgetPassword')
);
const ForgetPwd2 = React.lazy(() =>
  import('../pages/AuthenticationInner/ForgetPassword2')
);
const LockScreen = React.lazy(() =>
  import('../pages/AuthenticationInner/auth-lock-screen')
);
const LockScreen2 = React.lazy(() =>
  import('../pages/AuthenticationInner/auth-lock-screen-2')
);
const ConfirmMail = React.lazy(() =>
  import('../pages/AuthenticationInner/page-confirm-mail')
);
const ConfirmMail2 = React.lazy(() =>
  import('../pages/AuthenticationInner/page-confirm-mail-2')
);
const EmailVerification = React.lazy(() =>
  import('../pages/AuthenticationInner/auth-email-verification')
);
const EmailVerification2 = React.lazy(() =>
  import('../pages/AuthenticationInner/auth-email-verification-2')
);
const TwostepVerification = React.lazy(() =>
  import('../pages/AuthenticationInner/auth-two-step-verification')
);
const TwostepVerification2 = React.lazy(() =>
  import('../pages/AuthenticationInner/auth-two-step-verification-2')
);

// CSC ROUTES
const Home = React.lazy(() => import('../pages/Dashboard/index'));
// campaigns
const Campaigns = React.lazy(() => import('../pages/Campaigns/'));
const CampaignEdit = React.lazy(() => import('../pages/Campaigns/EditCampaign'));
const CreateCampaign = React.lazy(() =>
  import('../pages/Campaigns/CreateCampaign')
);
// clients
const Clients = React.lazy(() => import('pages/Clients'));
const ClientsProfile = React.lazy(() => import('pages/Clients/ClientsProfile'));
// agents
const Agents = React.lazy(() => import('pages/Agents'));
const AgentProfile = React.lazy(() => import('pages/Agents/AgentProfile'));
// settings
const Settings = React.lazy(() => import('pages/Settings'));
// notifications
const Notifications = React.lazy(() => import('pages/Notifications'));

const authProtectedRoutes = [
  // ADMIN ROUTES
  {
    path: '/home',
    component: Home,
    roles: [SUPER_ADMIN, ADMIN, CLIENT, AGENT],
  },
  // campaigns
  {
    path: '/campaign',
    component: Campaigns,
    roles: [SUPER_ADMIN, ADMIN, CLIENT, AGENT],
  },
  {
    path: '/campaign/:campaign_id/edit',
    component: CampaignEdit,
    roles: [SUPER_ADMIN, ADMIN, CLIENT],
  },
  {
    path: '/campaign/create',
    component: CreateCampaign,
    roles: [SUPER_ADMIN, ADMIN, CLIENT],
  },
  // clients
  { path: '/clients', component: Clients, roles: [SUPER_ADMIN, ADMIN] },
  {
    path: '/clients/:id',
    component: ClientsProfile,
    roles: [SUPER_ADMIN, ADMIN],
  },

  // agents
  { path: '/agents', component: Agents, roles: [SUPER_ADMIN, ADMIN] },
  {
    path: '/agent-profile',
    component: AgentProfile,
    roles: [SUPER_ADMIN, ADMIN, CLIENT],
  },
  {
    path: '/agents/:id',
    component: AgentProfile,
    roles: [SUPER_ADMIN, ADMIN, AGENT, CLIENT],
  },

  // profile
  {
    path: '/profile',
    component: UserProfile,
    roles: [SUPER_ADMIN, ADMIN, CLIENT, AGENT],
  },
  { path: '/settings', component: Settings, roles: [SUPER_ADMIN, ADMIN] },

  // notifications
  {
    path: '/notifications',
    component: Notifications,
    roles: [SUPER_ADMIN, ADMIN, CLIENT, AGENT],
  },

  // this route should be at the end of all other routes
  { path: '*', component: () => <Redirect to="/home" /> },
];

const publicRoutes = [
  { path: '/login', component: Login },
  { path: '/forgot-password', component: ForgetPwd },
  { path: '/register', component: Register },
  // Authentication Inner
  { path: '/admin-login', component: AdminLogin },
  { path: '/page-recoverpw', component: Recoverpw },
  { path: '/page-recoverpw-2', component: Recoverpw2 },
  { path: '/pages-forgot-pwd', component: ForgetPwd1 },
  { path: '/auth-recoverpw-2', component: ForgetPwd2 },
  { path: '/auth-lock-screen', component: LockScreen },
  { path: '/auth-lock-screen-2', component: LockScreen2 },
  { path: '/page-confirm-mail', component: ConfirmMail },
  { path: '/page-confirm-mail-2', component: ConfirmMail2 },
  { path: '/auth-email-verification', component: EmailVerification },
  { path: '/auth-email-verification-2', component: EmailVerification2 },
  { path: '/auth-two-step-verification', component: TwostepVerification },
  { path: '/auth-two-step-verification-2', component: TwostepVerification2 },
  { path: '/', exact: true, component: () => <Redirect to="/login" /> },
];

export { authProtectedRoutes, publicRoutes };
