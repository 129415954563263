import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { useAuth, useRole } from 'hooks';
import { Restrict } from 'hoc';
import { SUPER_ADMIN, AGENT, CLIENT, ADMIN } from 'constants';
import { getValueFromLocalStorage } from 'helpers';

const routes = {
  profile: {
    [AGENT]: '/home',
    [ADMIN]: '/home',
    [CLIENT]: '/home',
  },
  name: {
    [SUPER_ADMIN]: 'name',
    [AGENT]: 'name',
    [ADMIN]: 'name',
    [CLIENT]: 'company_name',
  },
};

const ProfileMenu = () => {
  const { role } = useRole();
  const { logout } = useAuth();

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = React.useState(false);

  return (
    <Dropdown
      isOpen={menu}
      toggle={() => setMenu(!menu)}
      className="d-inline-block"
    >
      <DropdownToggle
        className="btn header-item"
        id="page-header-user-dropdown"
        tag="button"
      >
        <span className="d-inline-block ms-2 me-1">
          {getValueFromLocalStorage(routes.name[role]) ?? role}
        </span>
        <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem tag="a" href={routes.profile[role]}>
          <i className="bx bx-user font-size-16 align-middle me-1" />
          Profile
        </DropdownItem>
        <Restrict roles={[ADMIN, SUPER_ADMIN]}>
          <DropdownItem tag="a" href="/settings">
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            Settings
          </DropdownItem>
        </Restrict>
        <div className="dropdown-divider" />
        <Button onClick={logout} className="dropdown-item" color="link">
          <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
          <span>Logout</span>
        </Button>
      </DropdownMenu>
    </Dropdown>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

export default withRouter(ProfileMenu);
