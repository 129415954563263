import { useState, useRef } from 'react';
import debounce from 'lodash/debounce';

const DEBOUNCE_MILLIS = 300;

export function useLoad<T>(
  defaultdata?: T,
  debounced?: boolean | number
): {
  data: T | undefined;
  loading: boolean;
  loaded: boolean;
  loadError: string;
  fetchData: (
    loader: (...args: any[]) => Promise<any>,
    ...rest: any[]
  ) => Promise<void>;
  reset: () => void;
} {
  const [loadStatus, setLoadStatus] = useState({
    data: defaultdata,
    loading: false,
    loaded: false,
    loadError: '',
  });
  let fetchData;

  const debounceInterval =
    typeof debounced === 'number' ? debounced : DEBOUNCE_MILLIS;

  function setLoading(): void {
    setLoadStatus({
      data: loadStatus.data,
      loading: true,
      loaded: false,
      loadError: '',
    });
  }

  function setDone(data): void {
    setLoadStatus({ data, loading: false, loaded: true, loadError: '' });
  }

  function setFailed(e?: string): void {
    setLoadStatus({
      data: defaultdata,
      loading: false,
      loadError: e || 'There is an error',
      loaded: false,
    });
  }

  async function fetchDataFunction(loader, ...rest): Promise<void> {
    setLoading();

    try {
      const data = await loader(...rest);

      if (!data) {
        setFailed();
      } else {
        setDone(data);
      }
    } catch (e) {
      let errorMessage = String(e);

      if (e.response) {
        try {
          const errorReponse = await e.response.json();
          errorMessage = errorReponse.error;
        } catch {
          errorMessage = String(e);
        }
      }
      // Prune certain error format:
      // 1. For AWS services, remove text after 'status code'
      errorMessage = errorMessage && errorMessage.split('status code')[0];

      setFailed(errorMessage);
    }
  }

  fetchData = fetchDataFunction;
  const debouncedFetchData = useRef(
    debounce(fetchDataFunction, debounceInterval)
  );
  if (debounced) {
    fetchData = debouncedFetchData.current;
  }

  function reset(): void {
    fetchData = undefined;
    setLoadStatus({
      data: defaultdata,
      loading: false,
      loaded: false,
      loadError: '',
    });
  }

  return { ...loadStatus, fetchData, reset };
}
