import { get, post, put } from 'helpers';

export const SAVE_ALL_AGENT_INFORMATION = 'SAVE_ALL_AGENT_INFORMATION';
export const SAVE_AGENT = 'SAVE_AGENT';
export const SAVE_FILES = 'SAVE_FILES';
export const DOCUMENTS = {
  SAVE_INE: 'SAVE_INE',
  SAVE_CURP: 'SAVE_CURP',
  SAVE_PROOF: 'SAVE_PROOF',
  SAVE_PROMISES: 'SAVE_PROMISES',
  REMOVE_FILES: 'REMOVE_FILES',
};

export const getAgents = async ({ page = 0, by = 12, search = '' }) =>
  await get('/agents', { page: page.toString(), by, search });

export const getAgentById = async ({ agent_id, user_id }) =>
  await get('/agent/detail', { agent_id, user_id });

export const getFilesById = async agent_id =>
  await post({ url: '/agent/files', data: { agent_id } });

export const getAgentInformation = async ({ agent_id, user_id }) =>
  await Promise.all([
    getAgentById({ agent_id, user_id }),
    getFilesById(agent_id),
    getIndustriesByAgentId(agent_id),
    getActivitiesByAgentId(agent_id),
    getSkillsByAgentId(agent_id),
    checkIfVideoExists(agent_id),
  ]);

export const uploadDocument = async data =>
  await put('/agent/file/update', data);

export const getIndustriesByAgentId = async agent_id =>
  await get('/items/industry-agent', { agent_id });

export const getActivitiesByAgentId = async agent_id =>
  await get('/items/function-agent', { agent_id });

export const getSkillsByAgentId = async agent_id =>
  await get('/items/skill-agent', { agent_id });

export const checkIfVideoExists = async id =>
  await get('/agent/checkvideo', { id });
