import React from 'react';
import { useRole } from 'hooks';
import PageNotFound from 'pages/PageNotFound';

export const withRole = roles => Component => props => {
  const { role } = useRole();
  if (roles.some(r => r === role)) return <Component {...props} />;
  return null;
};

export const Restrict = ({ children, roles }) => {
  const { role } = useRole();
  const isAllowed = roles.some(r => r === role);
  if (isAllowed) return <React.Fragment>{children}</React.Fragment>;
};

export const ValidatePermissions = ({ children, roles }) => {
  const { role } = useRole();
  if (!roles) return <PageNotFound />;

  const isAllowed = roles.some(r => r === role);
  if (isAllowed) return <React.Fragment>{children}</React.Fragment>;
  else return <PageNotFound />;
};
