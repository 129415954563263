import { call, put, takeEvery } from 'redux-saga/effects';

import { GET_CAMPAIGNS, GET_CURRENT_CAMPAIGN } from './actionTypes';
import {
  getCampaignsSuccess,
  getCurrentCampaignSuccess,
  campaignsError,
} from './actions';

import {
  get,
  getValueFromLocalStorage,
  isEmptyArray,
  handleAllResponses,
} from '../../helpers/';

function* getCampaigns({ payload: params }) {
  try {
    const { page, search, client_id, date_from, date_to, by } = params;
    const { status, data } = yield call(get, '/all-campaigns', {
      page,
      by,
      search,
      client_id,
      date_from,
      date_to,
      user_type: getValueFromLocalStorage('user_type'),
    });
    if (status === 200 && !isEmptyArray(data.data)) {
      yield put(
        getCampaignsSuccess({
          campaigns: data.data,
          total_pages: data.total_pages,
          actual_page: data.actual_page,
        })
      );
    } else if (status !== 200) {
      yield put(
        campaignsError('There was en error retrieving data from server.')
      );
    }
  } catch (error) {
    yield put(campaignsError(error));
  }
}

function* getSingleCampaign({ payload: campaign_id }) {
  try {
    const names = ['years', 'status', 'campaignDetail', 'campaignInfo'];
    const retrieveAllData = () =>
      Promise.all([
        get('/items/years'),
        get('/items/status'),
        get('/campaign-detail', { id: campaign_id }),
        get('/campaign/info', { id: campaign_id }),
      ]);
    const response = yield call(retrieveAllData);
    const { campaignDetail, years, status } = yield handleAllResponses(
      response,
      names
    );
    yield put(
      getCurrentCampaignSuccess({
        currentCampaign: campaignDetail.data.data[0],
        years: years.data,
        status: status.data,
      })
    );
  } catch (error) {
    yield put(campaignsError(error));
  }
}

function* CampaignsSaga() {
  yield takeEvery(GET_CAMPAIGNS, getCampaigns);
  yield takeEvery(GET_CURRENT_CAMPAIGN, getSingleCampaign);
}

export default CampaignsSaga;
