import { all, fork } from 'redux-saga/effects';

//public
// import AuthSaga from "./auth/saga"
import CampaignsSaga from './campaigns/saga';
import LayoutSaga from './layout/saga';
import contactsSaga from './contacts/saga';
import dashboardSaga from './dashboard/saga';

export default function* rootSaga() {
  yield all([
    //public
    // fork(AuthSaga),
    fork(CampaignsSaga),
    fork(LayoutSaga),
    fork(contactsSaga),
    fork(dashboardSaga),
  ]);
}
