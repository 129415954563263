import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

//Import Images
import error from '../assets/images/error-img.png';

const PageNotFound = () => {
  //meta title
  document.title = 'Page not found | Swiftlee';

  return (
    <React.Fragment>
      <Container className="d-flex flex-column justify-content-center align-items-center vh-100">
        <Row>
          <Col lg="12">
            <div className="text-center mb-5">
              <h1 className="display-2 font-weight-medium">
                4<i className="bx bx-buoy bx-spin text-primary display-3" />4
              </h1>
              <h4 className="text-uppercase">
                Sorry, looks like this page is broken or you don't have enough
                permissions.
              </h4>
              <div className="mt-5 text-center">
                <Link className="btn btn-primary " to="/home">
                  Back to home
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8" xl="6">
            <div>
              <img src={error} alt="" className="img-fluid" />
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default PageNotFound;
