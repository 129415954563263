import React, { createContext } from 'react';
import { useImmerReducer } from 'use-immer';
import { handleAllResponses, post, get } from 'helpers';

const LOGGED = 'LOGGED';
const SAVE_DATA_ADMIN_LOAD = 'SAVE_DATA_ADMIN_LOAD';
const SAVE_DATA_AGENT_LOAD = 'SAVE_DATA_AGENT_LOAD';
const RESTORE_STATE = 'RESTORE_STATE';
const SAVE_CAMPAIGNS = 'SAVE_CAMPAIGNS';
const SAVE_POSITIONS = 'SAVE_POSITIONS';
const SAVE_CLIENTS = 'SAVE_CLIENTS';
const SAVE_CLIENTS_ITEMS = 'SAVE_CLIENTS_ITEMS';
const SAVE_AGENTS = 'SAVE_AGENTS';
const SAVE_NOTIFICATIONS = 'SAVE_NOTIFICATIONS';
const LOAD_MORE_NOTIFICATIONS = 'LOAD_MORE_NOTIFICATIONS';

const initialState = {
  user: null,
  campaigns: {
    actual_page: '0',
    data: [],
    total_pages: 1,
  },
  agents: {
    actual_page: '0',
    data: [],
    total_pages: 1,
  },
  clients: {
    actual_page: '0',
    data: [],
    total_pages: 1,
  },
  positions: {
    actual_page: '0',
    data: [],
    total_pages: 1,
  },
  clients_items: [],
  items: {
    years: [],
    status: [],
    industry: [],
    activity: [],
    skill: [],
  },
  admins: null,
  notifications: {
    data: [],
    total_pages: 0,
    actual_page: '0',
  },
};

export const Context = createContext(initialState);

export const ContextReducer = (draft, action) => {
  switch (action.type) {
    case LOGGED: {
      draft.user = action.payload;
      return;
    }
    case SAVE_DATA_ADMIN_LOAD: {
      const {
        status,
        years,
        industry,
        activity,
        skill,
        clients_items,
        user,
        admins,
        campaigns,
      } = action.payload;
      draft.items = { activity, industry, skill, status, years };
      draft.clients_items = clients_items;
      draft.user = user;
      draft.admins = admins;
      draft.campaigns = campaigns;
      return;
    }
    case SAVE_DATA_AGENT_LOAD: {
      const { status, years, industry, activity, skill, user } = action.payload;
      draft.items = { activity, industry, skill, status, years };
      draft.user = user;
      return;
    }
    case SAVE_NOTIFICATIONS: {
      draft.notifications = action.payload;
      return;
    }
    case LOAD_MORE_NOTIFICATIONS: {
      draft.notifications.data = [
        ...draft.notifications.data,
        ...action.payload.data,
      ];
      draft.notifications.total_pages = action.payload.total_pages;
      draft.notifications.actual_page = action.payload.actual_page;
      break;
    }
    case SAVE_CAMPAIGNS: {
      draft.campaigns = action.payload;
      return;
    }
    case SAVE_POSITIONS: {
      draft.positions = action.payload;
      return;
    }
    case RESTORE_STATE: {
      const { campaigns, clients_items, items, clients, client } =
        action.payload;
      draft.campaigns = campaigns;
      draft.clients_items = clients_items;
      draft.items = items;
      draft.clients = clients;
      draft.client = client;
      draft.logged = true;
      return;
    }
    case SAVE_CLIENTS: {
      draft.clients = action.payload;
      return;
    }
    case SAVE_CLIENTS_ITEMS: {
      draft.clients_items = action.payload;
      return;
    }
    case SAVE_AGENTS: {
      draft.agents = action.payload;
      return;
    }
    default:
      return;
  }
};

export const ContextProvider = ({ children }) => {
  const [store, dispatch] = useImmerReducer(ContextReducer, initialState);

  const functions = {
    // ACTIONS
    loginSuccess: user => dispatch({ type: LOGGED, payload: user }),
    saveAllDataOnReload: state =>
      dispatch({
        type: SAVE_DATA_ADMIN_LOAD,
        payload: handleAllResponses(state, [
          'status',
          'years',
          'industry',
          'activity',
          'skill',
          'clients_items',
          'admins',
          'campaigns',
          'user',
        ]),
      }),
    saveDataOnAgentLoad: state =>
      dispatch({
        type: SAVE_DATA_AGENT_LOAD,
        payload: handleAllResponses(state, [
          'status',
          'years',
          'industry',
          'activity',
          'skill',
          'user',
        ]),
      }),
    saveDataOnClientLoad: state =>
      dispatch({
        type: SAVE_DATA_AGENT_LOAD,
        payload: handleAllResponses(state, [
          'status',
          'years',
          'industry',
          'activity',
          'skill',
          'user',
        ]),
      }),
    restoreState: state => dispatch({ type: RESTORE_STATE, payload: state }),
    saveCampaigns: campaigns =>
      dispatch({ type: SAVE_CAMPAIGNS, payload: campaigns }),
    savePositions: positions =>
      dispatch({ type: SAVE_POSITIONS, payload: positions }),
    saveClients: clients => dispatch({ type: SAVE_CLIENTS, payload: clients }),
    saveClientItems: clients =>
      dispatch({ type: SAVE_CLIENTS_ITEMS, payload: clients }),
    saveAgents: agents => dispatch({ type: SAVE_AGENTS, payload: agents }),
    saveNotifications: notifications =>
      dispatch({ type: SAVE_NOTIFICATIONS, payload: notifications }),
    addMoreNotifications: notifications =>
      dispatch({ type: LOAD_MORE_NOTIFICATIONS, payload: notifications }),
  };

  return (
    <Context.Provider value={[store, functions]}>{children}</Context.Provider>
  );
};
